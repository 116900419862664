:root {
    --mireille-purple: #301366;
    --gradient-pink: #d3208b;
    --gradient-blue: #879af2;
    --gradient-green: #4dc2b3;
    --mireille-purple-opacified: #f5f3f7;
    --gradient-pink-lightened: #e990c5;
    --gradient-blue-lightened: #c3cdf9;
    --gradient-green-lightened: #a6e1d9;
}

html,
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    position: fixed;
    display: block;
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    background-image: linear-gradient(
        90deg,
        var(--gradient-pink),
        var(--gradient-blue) 48%,
        var(--gradient-green)
    );
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overscroll-behavior-x: none;
}
h1,
p {
    margin: 0 0 1em;
}

@font-face {
    font-family: "WorkSansBold";
    font-style: normal;
    font-weight: 400;
    src: url("./assets/fonts/WorkSans-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "WorkSansItalic";
    font-style: italic;
    font-weight: 400;
    src: url("./assets/fonts/WorkSans-Italic.ttf") format("truetype");
}
@font-face {
    font-family: "WorkSansLight";
    font-style: italic;
    font-weight: 400;
    src: url("./assets/fonts/WorkSans-Light.ttf") format("truetype");
}

@font-face {
    font-family: "WorkSansMedium";
    font-style: normal;
    font-weight: 400;
    src: url("./assets/fonts/WorkSans-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "WorkSansRegular";
    font-style: normal;
    font-weight: 400;
    src: url("./assets/fonts/WorkSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "WorkSansSemiBold";
    font-style: normal;
    font-weight: 400;
    src: url("./assets/fonts/WorkSans-SemiBold.ttf") format("truetype");
}
