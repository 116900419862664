.authModal {
    /*margin: 2vw;*/
}

.authDialogBox {
    border-radius: calc(var(--ratio) * 25pt) !important;
}

.authPaper {
    margin: 2vw;
    max-width: 400pt;
    margin-left: auto;
    margin-right: auto;
}

.routineListIntroHolder {
    margin-bottom: calc(var(--ratio) * 20pt);
    padding-left: calc(var(--ratio) * 25pt);
    border-radius: calc(var(--ratio) * 25pt);

    background-color: white;
}

.routineListIntro {
    color: var(--mireille-purple);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.routineIntroHolder {
    color: var(--mireille-purple);
    display: block;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.introIllus {
    width: 130pt;
}

.pageHeartMobile {
    max-width: 850pt;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
