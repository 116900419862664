.SlidePromo {
    width: 100vw;
    min-height: 40vh;
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #faf9fb;
}

.SlidePromoMobile {
    width: 96vw;
    min-height: 65vh;
    padding: 2vw;
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #faf9fb;
}

.SlideLanding {
    width: 96vw;
    height: 80vh;
    padding: 2vw;
    background-image: linear-gradient(
        45deg,
        #d3208b 0%,
        #879af2 50%,
        #4dc2b3 100%
    );
}

.Slide1 {
    width: 96vw;
    min-height: 70vh;
    padding: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}

.Slide2 {
    width: 96vw;
    min-height: 50vh;
    padding: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #faf9fb;
}

.Slide3 {
    width: 96vw;
    min-height: 50vh;
    padding: 2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4dc2b3;
}

.Slide4 {
    width: 100vw;
    min-height: 203pt;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #faf9fb;
}

.pageHeart {
    width: 94vw;
    max-width: 850pt;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.pageHeartMobile {
    max-width: 850pt;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footerHeart {
    max-width: 1000pt;
    margin: auto;
    display: flex;
    justify-content: center;
}

.columnDisplay {
    padding-bottom: 3vh;
    padding-top: 3vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.picPlusLegendHolder {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 2vh;
}

.ImageHolder {
    display: flex;
    align-items: center;
    justify-content: right;
    padding: 2vh;
}

.ImageHolderLeft {
    display: flex;
    align-items: center;
    justify-content: left;
    padding: 2vh;
}

.OptionsImageHolder {
    margin-top: 4vh;
    margin-bottom: 2vh;
    display: flex;
    align-items: center;
}

.ImageHolderCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.OptionsImageHolderCenter {
    margin-top: 4vh;
    margin-bottom: 2vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.TextHolder {
    padding: 2vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.CTAHolder {
    margin-top: 35pt;
}

.CTAHolderMobile {
    margin-top: 30pt;
    display: flex;
    justify-content: center;
}

.FooterBlockHolder {
    padding: 3vh;
    display: flex;
    flex-direction: column;
}
.storeZone {
    padding: 2vh;
}

.smallStoreCTA {
    margin-top: 3vh;
    margin-right: 2vh;
    height: 4vh;
}

.storeCTA {
    margin-right: 3vh;
    height: 6vh;
}

.mireilleLogoImage {
    height: 3.5vh;
}

.fullExample {
    height: 60vh;
}

.fullExampleMobile {
    max-width: 30vw;
}

.portrait {
    width: 160pt;
    margin-left: 20pt;
}

.simpleExample {
    height: 250pt;
    max-width: 90vw;
}

.simpleExampleMobile {
    height: 250pt;
    max-width: 90vw;
}

.picto {
    height: 2vh;
    margin-right: 1vh;
}

.HomeTitle {
    font-family: "WorkSansBold";
    font-size: 4vh;
    font-stretch: normal;
    font-style: normal;
    color: #ffffff;
    flex-wrap: wrap;
    margin-bottom: 4vh;
}

.HomeTitleMobile {
    font-stretch: normal;
    color: #ffffff;
    flex-wrap: wrap;

    text-align: center;
}

.HomeTitlePurple {
    font-family: "WorkSansBold";
    font-size: 4vh;
    font-stretch: normal;
    font-style: normal;
    color: #301366;
    flex-wrap: wrap;
}

.HomeTitlePurpleMobile {
    font-stretch: normal;
    font-style: normal;
    color: #301366;
    text-align: center;
}

.HomeContent {
    margin-top: 2vh;
    font-family: "WorkSansRegular";
    font-size: 2.2vh;
    font-stretch: normal;
    font-style: normal;
    white-space: pre-wrap;
    color: #ffffff;
}

.HomeContentQuote {
    margin-top: 4vh;
    margin-bottom: 2vh;
    font-family: "WorkSansItalic";
    font-size: 2.4vh;
    font-stretch: normal;
    font-style: normal;
    white-space: pre-wrap;
    color: #ffffff;
}

.HomeContentPurple {
    font-family: "WorkSansRegular";
    font-size: 2.2vh;
    font-stretch: normal;
    font-style: normal;
    white-space: pre-wrap;
    color: #301366;
}

.HomeContentTitlePurple {
    margin-top: 2vh;
    font-family: "WorkSansBold";
    font-size: 2.2vh;
    font-stretch: normal;
    font-style: normal;
    white-space: pre-wrap;
    color: #301366;
    margin-bottom: 2vh;
}

.ExpertLegend {
    text-align: center;
    margin-top: 2vh;
    font-family: "WorkSansRegular";
    font-size: 2.2vh;
    font-stretch: normal;
    font-style: normal;
    white-space: pre-wrap;
    color: #301366;
}

.ExpertLegendTitle {
    text-align: center;
    margin-top: 2vh;
    font-family: "WorkSansBold";
    font-size: 2.2vh;
    font-stretch: normal;
    font-style: normal;
    white-space: pre-wrap;
    color: #301366;
}

.StrokedPrice {
    text-decoration: line-through;
}

.InfluencerName {
    color: white;
    padding: 0vh 1vh;
    background-image: linear-gradient(
        45deg,
        #d3208b 0%,
        #879af2 50%,
        #4dc2b3 100%
    );
}

.freeTrialLabel {
    margin-top: 1.5vh;
    margin-bottom: 3vh;
}

.PartnerImageHolder {
}

.partnerIllus {
    height: 25vh;
}

.flower1 {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 12vh;
}

.flower2 {
    position: absolute;
    bottom: 0;
    height: 10vh;
}

.flower3 {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 15vh;
}

.iosOption {
    height: 3.9vh;
    margin-top: 2.1vh;
}

.androidOption {
    height: 2.4vh;
    margin-top: 2.4vh;
    margin-left: 36px;
    margin-right: 39px;
}

.mireilleTradOption {
    margin-top: 1.5vh;
    height: 4.65vh;
}

.legalCTA {
    color: rgba(48, 19, 102, 0.3);
    font-size: 2.2vh;
    font-family: "WorkSansRegular";
    text-transform: none;
}

.legalCTASeparator {
    color: rgba(48, 19, 102, 0.3);
    font-size: 2.2vh;
    font-family: "WorkSansRegular";
    text-transform: none;
    margin-left: 1vh;
    margin-right: 1vh;
}

.legalCTAHolder {
    display: flex;
    flex-direction: row;
    margin-top: 0.5vh;
}
