:root {
    --ratio: 1;
}
@media all and (max-width: 450px) {
    :root {
        --ratio: 0.8;
    }
}

.scrollPaper {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    height: calc(var(--ratio) * 520pt);
    align-items: center;
    width: 360pt;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    border-radius: calc(var(--ratio) * 25pt);
}

.scrollPaper::-webkit-scrollbar {
    display: none;
}

.scrollPaperMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90vh;
    width: 100vw;
    margin-left: auto;
    margin-right: auto;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
}

.scrollPaperMobile::-webkit-scrollbar {
    display: none;
}
.tradTitle {
    color: #301366;
    text-align: center;
    display: flex;
    padding-top: 20pt;
    padding-bottom: 10pt;
}

.tradPlaceholder {
    font-family: "WorkSansBold";
    font-size: calc(var(--ratio) * 1.3rem);
}

.tradFieldHolder fieldset {
    border-radius: 20pt;
}

.tradInput {
    padding: 5pt;
    border-radius: 5pt;
    border-color: transparent !important;
    background-color: rgba(48, 19, 102, 0.05);
    width: 300pt;
    max-width: 90%;
    text-align: top;
    min-height: 200pt;
}

.inputElement .MuiFilledInput-root {
    border-radius: 20pt;
}

.inputElement .MuiOutlinedInput-root {
    border-radius: 20pt;
    font-size: calc(var(--ratio) * 1.3rem);
}

.tradInput:hover {
    border-color: transparent !important;
}

.tradDescription {
    width: 312pt;
    max-width: 90%;
    margin-top: 20pt;
    max-width: 86vw;
    display: flex;
    text-align: center;
    color: #301366;
}

.tradInvite {
    width: 312pt;
    max-width: 90%;
    margin-top: 20pt;
    margin-bottom: 5pt;
    color: #301366;
}

.textFieldInput {
    font-family: "WorkSansBold";
    font-size: 13pt;
    line-height: 15pt;
    letter-spacing: 0.3pt;
    color: #301366;
}

.tradActionBar {
    max-width: 90%;
    display: flex;
    flex-direction: row;
    margin-top: 15pt;
    align-items: center;
}

.buttonWrapper {
    align-items: center;
    text-align: center;
    margin: 0pt 5pt;
}

.compoContainer {
    width: 312pt;
    max-width: 90%;
    margin-top: 30pt;
}

.ingredientRowHolder {
    margin-top: 10pt;
    max-width: 86vh;
}
.compositionName {
    color: #4f5858;
    flex-wrap: "nowrap";
}
.compositionState {
    color: #4f5858;
    margin-top: 5pt;
}
.detailsCTA {
    color: #5bcee9;
    font-size: 1.2vh;
    font-style: "normal";
    text-align: "right";
    font-family: "WorkSansBold";
    text-transform: "uppercase";
}

.ingredientDetailBox {
    padding: 25pt;
    padding-top: 15pt;
    border-radius: 25pt;
    width: 312pt;
    max-width: 80%;
}

.IngredientDetailTitle {
    min-height: 40pt;
    display: flex;
    color: #4f5858;
    flex-wrap: nowrap;
    align-items: end;
}

.fullExample {
    max-height: 75vh;
}

.IconHolder {
    height: 40pt;
    text-align: center;
    display: flex;
    align-items: end;
}

.gradeHolder {
    width: 30px;
    height: 40.5px;
}

.IngredientDetailSubTitle {
    color: #4f5858;
}

.IngredientDetailContent {
    color: #4f5858;
}

.IngredientDetailHolder {
    margin-bottom: 1.5vh;
}

.IngredientDetailSubTitleWithIllus {
    line-height: 6vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.detailsHolder {
    margin-top: 20pt;
    color: #4f5858;
}

.detailsContent {
    margin-top: 10pt;
}

.adviceTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.advicePortrait {
    width: 30pt;
    margin-right: 10pt;
}
