:root {
    --ratio: 1;
    --ultra: 1;
}
@media all and (max-width: 450px) {
    :root {
        --ratio: 0.8;
    }
}

@media all and (max-width: 370px) {
    :root {
        --ultra: 0.95;
    }
}

.simpleLayout {
    width: 460px;
    height: 100%;
    max-width: 100%;
    padding-bottom: 50px;
    /*margin-top: 50pt;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    overflow-y: scroll;
}

.simpleLayout::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.simpleLayout {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.RoutineRow {
    border-radius: calc(var(--ratio) * 25pt);
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
    height: calc(var(--ratio) * 50pt);
    width: 100%;
    margin-top: calc(var(--ratio) * 10pt);
    margin-bottom: calc(var(--ratio) * 10pt);
}

.routineListIntroHolder {
    margin-bottom: calc(var(--ratio) * 20pt);
    padding-left: calc(var(--ratio) * 25pt);
    border-radius: calc(var(--ratio) * 25pt);

    background-color: white;
}

.routineListIntro {
    color: var(--mireille-purple);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.routineIntroHolder {
    color: var(--mireille-purple);
    display: block;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.introIllus {
    width: 130pt;
}

.RoutineAuthorHolder {
    height: calc(var(--ratio) * 50pt);
    display: flex;
    flex-direction: row-reverse;
}

.authorName {
    height: 100%;
    text-align: end;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
    color: var(--mireille-purple);
}

.authorIllus {
    margin-left: calc(var(--ratio) * 20pt);
    height: calc(var(--ratio) * 50pt);
    width: calc(var(--ratio) * 50pt);
    border-radius: calc(var(--ratio) * 25pt);
    object-fit: cover;
}

.RoutineNameMask {
    flex-grow: 1;
    margin-left: calc(var(--ratio) * 20pt);
    display: inline;
    align-items: center;
    flex-wrap: wrap;
    color: var(--mireille-purple);
}

@supports (background-clip: text) {
    .RoutineNameMask {
        background: linear-gradient(
            90deg,
            var(--gradient-pink),
            var(--gradient-blue) 48%,
            var(--gradient-green)
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: 0px 0px #00000000;
    }
    .routineIntroHolder {
        background-image: linear-gradient(
            90deg,
            var(--gradient-pink),
            var(--gradient-blue) 48%,
            var(--gradient-green)
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: 0px 0px #00000000;
    }
}

.routineElementCard {
    max-width: calc(var(--ratio) * 250pt);
    margin: auto;
    padding: calc(var(--ratio) * 25pt);
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: calc(var(--ratio) * 25pt);
    align-items: center;
}

.routineElementTitle {
    display: flex;
    flex-direction: row;
    color: var(--mireille-purple);
    align-items: center;
    justify-content: center;
}

.routineElementDescription {
    display: flex;
    flex-direction: row;
    color: var(--mireille-purple);
    white-space: pre-line;
    align-items: center;
    justify-content: center;
    margin-top: 25pt;
    margin-bottom: 25pt;
}

.routineSection {
    display: flex;
    flex-direction: column;
    color: var(--mireille-purple);
    align-items: center;
    justify-content: center;
    margin-top: 25pt;
}

.routineDisplayScreen {
    background: linear-gradient(
        90deg,
        var(--gradient-pink),
        var(--gradient-blue) 48%,
        var(--gradient-green)
    );
}

.routineTitle {
    margin-top: calc(var(--ratio) * 10pt);
    margin-bottom: calc(var(--ratio) * 20pt);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
}

.ritualBlock {
    margin-top: 20pt;
    width: 100%;
    background-color: white;
    border-radius: calc(var(--ratio) * 20pt);
}

.ritualTitle {
    display: block;
    margin-bottom: 10px;
    color: var(--gradient-pink);
    align-items: center;
    text-align: center;
}

.offersTitle {
    display: block;
    margin-bottom: 10px;
    color: var(--gradient-pink);
    align-items: center;
    text-align: center;
    cursor: pointer;
    border: 2px solid var(--gradient-pink);
    border-radius: calc(var(--ratio) * 5pt);
    padding: 6px;
}

@supports (background-clip: text) {
    .ritualTitle {
        background-image: linear-gradient(
            90deg,
            var(--gradient-pink),
            var(--gradient-blue) 48%,
            var(--gradient-green)
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: 0px 0px #00000000;
    }
}

.ritualBlockContent {
    text-align: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: var(--mireille-purple);
    padding: 35px 30px 25px 30px;
}

.ritualTitleStepsSeparator {
    margin-top: 6pt;
    margin-bottom: 6pt;
}

.ritualRules {
    margin-top: 20px;
    border: solid 2px var(--gradient-pink);
    border-radius: calc(var(--ratio) * 23.5px);
    padding: 12px 37px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: var(--gradient-pink);
}

.ritualFreq {
    padding-left: 15px;
}

.stepIntro {
    text-align: center;
    color: var(--mireille-purple);
    padding-bottom: 20px;
    width: 100%;
}

.stepCounter {
    flex-grow: 1;
    text-align: center;
    align-items: center;
    align-content: center;
}

.stepIndex {
    margin-bottom: 12pt;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.stepIntroHolder {
    height: 54px;
}

.adviceBlock {
    padding: 20px 65px;
    background-color: rgba(48, 19, 102, 0.05);
    border-bottom-left-radius: calc(var(--ratio) * 20pt);
    border-bottom-right-radius: calc(var(--ratio) * 20pt);
    display: flex;
    align-items: center;
    flex-direction: column;
    color: var(--mireille-purple);
}

.productPicHolder {
    height: 100px;
    width: 100px;
    border-radius: 50px;
    margin-bottom: 12px;
    overflow: hidden;
    background: linear-gradient(
        45deg,
        var(--gradient-pink),
        var(--gradient-blue) 52%,
        var(--gradient-green)
    );
}

.promptPicHolder {
    height: 80px;
    width: 80px;
    border-radius: 40px;
    margin-bottom: 12px;
    overflow: hidden;
    background: linear-gradient(
        45deg,
        var(--gradient-pink),
        var(--gradient-blue) 52%,
        var(--gradient-green)
    );
}

.promptPic {
    height: 80px;
    width: 80px;
    object-fit: contain;
}

.productPic {
    height: 100px;
    width: 100px;
    object-fit: contain;
}

.ctaHolder {
    display: flex;
    flex-grow: 1;
    flex-direction: column-reverse;

    align-items: center;
}

.meanDetailsHolder {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    height: 60pt;
    justify-content: center;
}

.showTutoHolder {
    height: 23px;
    margin-top: 27px;
    display: flex;
    justify-content: center;
}

.stickyHeader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    height: 100px;
    color: white;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background: linear-gradient(
        90deg,
        var(--gradient-pink),
        var(--gradient-blue) 48%,
        var(--gradient-green)
    );
    animation: 500ms ease-in-out 0.5s normal none 1 running;
    padding-top: 0px;
    padding-bottom: 0px;
}
.unstickyHeader {
    background-color: "transparent";
    color: white;
    padding-top: 0px;
    padding-bottom: 0px;
    height: 100px;
    width: 100%;
}

.headerHolder {
    width: 460px;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.backButton {
    min-width: 80px;
    margin: 5px;
}

.cheatFilters {
    width: 100%;
}

.shoppingList {
    width: calc(var(--ultra) * 370px);
    min-height: 44px;
    max-height: 70vh;
    /*padding: 29px 35px 23px 30px;*/
    opacity: 0.2;
    border-top-left-radius: calc(var(--ratio) * 20pt);
    border-top-right-radius: calc(var(--ratio) * 20pt);
    box-shadow: 0 14px 22px 0 rgba(0, 0, 0, 0.04);
    background-color: #fff;
    position: fixed;
    bottom: 0px;
    right: 0px;
}

.shoppingListHeader {
    width: calc(var(--ultra) * 350px);
    color: white;
    height: 24px;
    padding: calc(var(--ultra) * 10px);
    display: flex;
    align-items: center;
}

.shoppingListIllus {
    margin-left: 30px;
    margin-right: 30px;
    height: 22px;
    width: 22px;
}

.shoppingListTitle {
}

.shoppingListMask {
    width: calc(var(--ultra) * 370px);
    min-height: 44px;
    max-height: 70vh;
    /*padding: 29px 35px 23px 30px;*/
    border-top-left-radius: calc(var(--ratio) * 20pt);
    border-top-right-radius: calc(var(--ratio) * 20pt);
    box-shadow: 0 14px 22px 0 rgba(0, 0, 0, 0.04);
    position: fixed;
    bottom: 0px;
    right: 0px;
    overflow-x: hidden;
}

.shoppinListBackgroundHolder {
    width: calc(var(--ultra) * 370px);

    min-height: 44px;
    max-height: 70vh;
    position: relative;
    overflow: hidden;
}

.shoppingListBackground {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 100vw;
    min-height: 44px;
    background: linear-gradient(
        90deg,
        var(--gradient-pink),
        var(--gradient-blue) 48%,
        var(--gradient-green)
    );
}

.shoppingListContent {
    width: calc(var(--ultra) * 370px);
    position: absolute;
    right: 0px;
    bottom: 0px;
    min-height: 44px;
    max-height: 70vh;
    background-color: rgba(255, 255, 255, 0.2);
    overflow-y: scroll;
}

.shoppingListContent::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.shoppingListContent {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.productItem {
    width: calc(var(--ultra) * 310px);
    height: calc(var(--ultra) * 55px);
    display: flex;
    padding: calc(var(--ultra) * 10px);
    margin-left: calc(var(--ultra) * 20px);
    margin-right: calc(var(--ultra) * 10px);
    margin-bottom: calc(var(--ultra) * 10px);
    background-color: #fff;
    border-radius: 10px;
    align-items: center;
}

.productItemPicHolder {
    height: calc(var(--ultra) * 55px);
    width: calc(var(--ultra) * 55px);
    border-radius: calc(var(--ultra) * 28px);
    overflow: hidden;
    background: linear-gradient(
        45deg,
        var(--gradient-pink),
        var(--gradient-blue) 52%,
        var(--gradient-green)
    );
}

.productItemPic {
    height: calc(var(--ultra) * 55px);
    width: calc(var(--ultra) * 55px);
    object-fit: contain;
}

.productItemName {
    color: var(--mireille-purple);
    width: calc(var(--ultra) * 245px);
    margin-left: calc(var(--ultra) * 10px);
}

.outLink {
    color: var(--mireille-purple);
}
