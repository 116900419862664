:root {
    --ratio: 1;
}
@media all and (max-width: 450px) {
    :root {
        --ratio: 0.8;
    }
}

.offersDisplayer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.salesBlock {
    width: 200pt;
    height: 470pt;
    background-color: white;
    border-radius: calc(var(--ratio) * 25pt);
    display: flex;
    flex-direction: column;
    padding: 20pt;
    padding-top: 5pt;
    margin: 5pt;
}

.background {
    width: 96vw;
    min-height: 60vh;
    padding: 2vw;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

.pageHeart {
    width: 94vw;
    max-width: 1000pt;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.HomeContentPurple {
    font-stretch: normal;
    font-style: normal;
    white-space: pre-wrap;
    color: #301366;
}

.offerDescription {
    font-style: normal;
    color: #301366;
}

.HomeContentTitleHolder {
    width: 190pt;
    text-align: center;
    margin: 5pt;
}

.HomeContentHolder {
    width: 190pt;
    text-align: justify;
    margin: 5pt;
    flex-grow: 1;
}
.PricingHolder {
    margin: 5pt;
    text-align: center;
}

.MonthlyPricingHolder {
    margin-bottom: 30pt;
    text-align: center;
    color: #4dc2b3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.PerMonthZone {
    height: 100%;
    margin-left: 2pt;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 2pt;
}

.HomeContentTitlePurple {
    font-stretch: normal;
    font-style: normal;
    white-space: pre-wrap;
    color: #301366;
}

.salesCTAHolder {
    width: 190pt;
    margin-top: 5pt;
    display: flex;
    align-items: center;
    justify-content: center;
}
.paywallHeader {
    margin-top: 10pt;
    margin-bottom: 20pt;
    max-width: 400pt;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    padding: 5pt;
}

.paywallCatch {
    background: linear-gradient(
        90deg,
        var(--gradient-pink),
        var(--gradient-blue) 48%,
        var(--gradient-green)
    );
}

.catchHolder {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 600pt;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: calc(var(--ratio) * 55px);
    margin-bottom: calc(var(--ratio) * 25px);
}

.catchIllusHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    margin-left: calc(var(--ratio) * 25pt);
    margin-right: calc(var(--ratio) * 25pt);
    margin-bottom: 15pt;
}

.catchTextHolder {
    display: flex;
    width: 400px;
    flex-direction: column;
    color: white;
}

.catchTitleHolder {
}

.catchSubtitleHolder {
    margin-top: 20pt;
}

.catchCTAHolder {
    margin-top: 30pt;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.paywallTableSection {
    background-color: #faf9fb;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.paywallTable {
    display: flex;
    flex-direction: row;
    max-width: 98%;
}

.paywallColumnTitle {
    display: flex;
    width: 350px;
    max-width: 50%;
    flex-direction: column;
}
.paywallColumnBase {
    display: flex;
    width: 250px;
    max-width: 25%;
    flex-direction: column;
}
.paywallColumnPremium {
    display: flex;
    width: 250px;
    max-width: 25%;
    flex-direction: column;
    background: linear-gradient(
        45deg,
        var(--gradient-pink-lightened),
        var(--gradient-blue-lightened) 48%,
        var(--gradient-green-lightened)
    );
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.paywallTableTitleCell {
    height: 81px;
    color: #301366;
    display: flex;
    align-items: center;
    text-align: left;
    border-bottom: #301366 1px solid;
}

.paywallTableCell {
    height: 81px;
    color: #301366;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: #301366 1px solid;
}
.tableHeaderCell {
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.paywallTableCellBase {
    height: 81px;
    color: #78669c;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: #301366 1px solid;
}

.paywallTail {
    height: 20px;
}

.baseCartouche {
    width: 95%;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: white;
    background-color: #b7acc9;
    height: 100px;
    border-radius: 20px;
}

.premiumCartouche {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: white;
    background: linear-gradient(
        45deg,
        var(--gradient-pink),
        var(--gradient-blue) 48%,
        var(--gradient-green)
    );
    height: 100px;
    border-radius: 20px;
}

.paywallReviewsSection {
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.reviewSectionTitle {
    margin-bottom: 25px;
    color: #301366;
}

.paywallReviewsSlider {
}

.reviewsHolder {
    width: 840px;
    max-width: 100%;
    color: #301366;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.reviewHolder {
    width: 400px;
    max-width: 98%;
    padding: 10px;
    height: 250px;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.reviewHead {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.reviewTitleHolder {
    display: flex;
    flex-direction: column;
}

.marginBottomText {
    margin-bottom: 5px;
}
