:root {
    --ratio: 1;
}
@media all and (max-width: 450px) {
    :root {
        --ratio: 0.8;
    }
}

.introIllus {
    width: 130pt;
    margin-top: -20pt;
    margin-bottom: 10pt;
}

.pageHeart {
    max-width: 1000pt;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.questionLayout {
    width: 360pt;
    height: calc(var(--ratio) * 520pt);
    border-radius: calc(var(--ratio) * 25pt);
    background-color: white;
    padding: calc(var(--ratio) * 20pt);
    padding-left: calc(var(--ratio) * 25pt);
    padding-right: calc(var(--ratio) * 25pt);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 50pt;
}

.hairAnswersBlock {
    margin-top: calc(var(--ratio) * 20pt);
    display: flex;
    flex-direction: column;
}

.answersBlock {
    margin-top: calc(var(--ratio) * 10pt);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.filler {
    flex-grow: 1;
}
/*.standardAnswer {
    padding: 5pt;
    border: 1pt solid black;
    border-radius: 5pt;
}

.standardAnswerSelected {
    padding: 5pt;
    border: 1pt solid red;
    border-radius: 5pt;
}*/

.standardAnswer {
    margin: calc(var(--ratio) * 4pt);
    padding: calc(var(--ratio) * 6pt) calc(var(--ratio) * 14pt)
        calc(var(--ratio) * 6pt) calc(var(--ratio) * 14pt);
    border-radius: calc(var(--ratio) * 25pt);
    background-color: var(--mireille-purple-opacified);
    color: var(--mireille-purple);
    border: 1pt solid var(--mireille-purple-opacified);
}

.hairTextureAnswer {
    bottom: 0pt;
    margin: calc(var(--ratio) * 8pt);
    width: calc(var(--ratio) * 100pt);
    height: calc(var(--ratio) * 100pt);
    border-radius: calc(var(--ratio) * 25pt);
    overflow: hidden;
    background-color: white;
    box-shadow: 0 14px 27px 0 rgba(0, 0, 0, 0.09);
}

.hairTextureAnswerLabel {
    width: calc(var(--ratio) * 100pt);
    margin-top: calc(var(--ratio) * 60pt);
    height: calc(var(--ratio) * 40pt);
    color: var(--mireille-purple);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.questionLabel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: calc(var(--ratio) * 30pt);
    margin-top: calc(var(--ratio) * 10pt);
    margin-bottom: calc(var(--ratio) * 10pt);
    color: var(--mireille-purple);
}

.questionSubLabel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: calc(var(--ratio) * 30pt);
    margin-right: calc(var(--ratio) * 30pt);
    margin-bottom: calc(var(--ratio) * 10pt);
    color: var(--mireille-purple);
}

.bypass {
    margin-bottom: calc(var(--ratio) * 40pt);
    color: var(--mireille-purple);
    text-decoration: underline;
}

.actionBar {
    margin-top: calc(var(--ratio) * 20pt);
    display: flex;
    flex-direction: column;
}

.profileDisplayLayout {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.quadrantDisplayColumn {
    width: calc(var(--ratio) * 300pt);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    text-align: center;
    padding: calc(var(--ratio) * 23pt);
    padding-bottom: 0pt;
    margin: auto;
    margin-top: 0pt;
}

.quadrantTitle {
    width: calc(var(--ratio) * 200pt);
    margin: calc(var(--ratio) * 10pt);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    text-align: center;
}

.quadrantLegendDisplayColumn {
    width: calc(var(--ratio) * 250pt);
    margin: calc(var(--ratio) * 25pt);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: calc(var(--ratio) * 25pt);
}

.decorRow {
    width: calc(var(--ratio) * 360pt);
    height: calc(var(--ratio) * 110pt);
    display: flex;
    flex-direction: row;
}
.decorLayout {
    width: calc(var(--ratio) * 360pt);
    height: calc(var(--ratio) * 330pt);
}

.quadrantLayout {
    margin-top: calc(var(--ratio) * -330pt);
    width: calc(var(--ratio) * 360pt);
    height: calc(var(--ratio) * 330pt);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.strengthDecorHolder {
    width: calc(var(--ratio) * 42pt);
    height: calc(var(--ratio) * 42pt);
    border-radius: calc(var(--ratio) * 21pt);
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.decorIllus {
}

.strengthLegendTitle {
    color: var(--mireille-purple);
}

.illusHolder {
    width: calc(var(--ratio) * 35pt);
}

.strengthLegendTitleHolder {
    display: flex;
    width: calc(var(--ratio) * 250pt);
    flex-direction: row;
    margin-top: calc(var(--ratio) * 20pt);
    height: calc(var(--ratio) * 22pt);
    align-items: center;
}

.strengthFullLegendTitleHolder {
    display: flex;
    flex-direction: row;
    color: var(--mireille-purple);
    align-items: center;
    justify-content: center;
}
.legendDefinitionHolder {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.gabyPicHolder {
    width: calc(var(--ratio) * 250pt);
    text-align: center;
    margin-top: calc(var(--ratio) * -10pt);
    margin-bottom: calc(var(--ratio) * 10pt);
}

.columnSeparator {
    width: 25pt;
}

.resultCardHolder {
    width: calc(var(--ratio) * 250pt);
    margin: auto;
    margin-top: calc(var(--ratio) * 25pt);
    margin-bottom: calc(var(--ratio) * 70pt);
    padding: calc(var(--ratio) * 17pt);
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: calc(var(--ratio) * 25pt);
}

.profileCtaCard {
    width: calc(var(--ratio) * 250pt);
    margin: auto;
    padding: calc(var(--ratio) * 25pt);
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: calc(var(--ratio) * 25pt);
}

.emptyGauge {
    height: calc(var(--ratio) * 6pt);
    width: calc(var(--ratio) * 250pt);
    margin-top: calc(var(--ratio) * 10pt);
    margin-bottom: calc(var(--ratio) * 10pt);
    background-color: var(--mireille-purple-opacified);
    border-radius: calc(var(--ratio) * 5pt);
}

.minGauge {
    height: calc(var(--ratio) * 6pt);
    width: calc(var(--ratio) * 62pt);
    background-color: var(--gradient-pink);
    border-radius: calc(var(--ratio) * 5pt);
}

.medInfGauge {
    height: calc(var(--ratio) * 6pt);
    width: calc(var(--ratio) * 125pt);
    background-color: #997eda;
    border-radius: calc(var(--ratio) * 5pt);
}

.medSupGauge {
    height: calc(var(--ratio) * 6pt);
    width: calc(var(--ratio) * 187pt);
    background-color: #73a9dc;
    border-radius: calc(var(--ratio) * 5pt);
}

.maxGauge {
    height: calc(var(--ratio) * 6pt);
    width: calc(var(--ratio) * 250pt);
    background-color: var(--gradient-green);
    border-radius: calc(var(--ratio) * 5pt);
}

.legendPromptPopin {
    padding: calc(var(--ratio) * 25pt);
    margin-top: calc(var(--ratio) * 30pt);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(var(--ratio) * 200pt);
    border-radius: calc(var(--ratio) * 25pt);
}

.profileBlock {
    border-radius: 5pt;
    padding: calc(var(--ratio) * 10pt);
    background-color: rgba(48, 19, 102, 0.05);
    margin-bottom: calc(var(--ratio) * 25pt);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.profileRecap {
    display: flex;
    color: var(--mireille-purple);
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: calc(var(--ratio) * 20pt);
    margin-bottom: calc(var(--ratio) * 20pt);
}

.profileDimHolder {
    display: flex;
    flex-direction: row;
    margin-top: calc(var(--ratio) * 4pt);
    margin-bottom: calc(var(--ratio) * 4pt);
    width: 80%;
}

.profileDimTitle {
    text-align: left;
}

.profileDimValue {
    text-align: right;
    flex-grow: 1;
}
