.PressColumn {
    width: 300pt;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
}

.background {
    width: 96vw;
    min-height: 60vh;
    padding: 2vw;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}

.pageHeart {
    width: 94vw;
    max-width: 700pt;
    display: flex;
    flex-wrap: wrap;
    flex-direction: Column;
    justify-content: center;
    margin: auto;
}

.BlockHolderTitle {
    color: white;
    margin-bottom: 15pt;
    text-align: center;
}

.BlockHolder {
    padding: 5pt;
    margin-bottom: 20pt;
    border-radius: 5pt;
    border: 1px solid white;
}

.ArticleLabel {
    color: #301366;
    text-decoration: none;
    font-style: italic;
}

.quoteHolder {
    padding: 10pt;
    text-align: center;
}

.ContactBrick {
    padding-top: 10pt;
}

.ArticleBrick {
    background-color: white;
    border-radius: 5pt;
    width: 220pt;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10pt;
}

.CPBrick {
    background-color: white;
    border: 1px solid white;
    border-radius: 5pt;
    width: 320pt;
    max-width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10pt;
}

.illusHolder {
    display: flex;
    height: 90pt;
    padding: 5pt;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.CPillusHolder {
}

.CPIllus {
    width: 320pt;
    max-width: 90vw;
    border-radius: 5pt;
}

.ArticleIllus {
    max-width: 150pt;
    max-height: 80pt;
}

.section {
    width: 94vw;
    max-width: 700pt;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    margin: auto;
}

.ContactLine {
    margin: 5pt;
    color: white;
    text-decoration: none;
}
