
.mireilleHeader {
    height: 10vh
}

.mireilleMenu {
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 5vw;
}

.mobileMenuHolder {
    z-index: 100;
    margin-top: 2vh;
    padding-top: 5vh;
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color:  #301366;
    padding-right: 5vw;
    right: 2vh;
    padding-bottom: 3vh;
    padding-left: 1vh;
    padding-right: 1vh;
}

.mireilleHamburgerMenu {
    z-index: 101;
    margin-top: 3vh;
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 5vw;
}

.illusHolder {
    position: absolute;
    left: 5vw;
}

.logoImage {
    margin-top: 4vh;
    height: 3.5vh;
}

.mireilleMenuEntry {
    margin-top: 3vh;
    text-align: center;
    margin-left: 1vw;
    margin-right: 1vw;
}
.mireilleMenuLabel {
  color: #ffffff;
  text-decoration: none;
}