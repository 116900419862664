:root {
    --ratio: 1;
}
@media all and (max-width: 450px) {
    :root {
        --ratio: 0.8;
    }
}

.accountDataHolder {
    background-color: white;
    margin: 10pt;
    border-radius: calc(var(--ratio) * 25pt);
}

.salesBlock {
    width: 200pt;
    height: 190pt;
    background-color: white;
    border-radius: calc(var(--ratio) * 25pt);
    display: flex;
    flex-direction: column;
    padding: 20pt;
    margin: 5pt;
}

.pageHeart {
    width: 94vw;
    max-width: 1000pt;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5pt;
}

.manageableAccountDataHolder {
    border: 1px solid #301366;
    margin-top: 10pt;
    color: #301366;
    border-radius: 5pt;
    display: flex;
    flex-direction: row;
}
.textDisplay {
    max-width: 170pt;
    text-overflow: clip;
}

.manageableAccountData {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin: 5pt;
}

.manageAccountImage {
    max-height: 2vh;
    max-width: 2vh;
}

.imagePlaceHolderBox {
    width: 20pt;
    height: 4vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.manageDataSubTitleHolder {
    margin-top: 20pt;
    margin-bottom: 10pt;
    color: #301366;
}

.manageDataTitleHolder {
    margin-top: 20pt;
    margin-bottom: 10pt;
    color: #301366;
    display: flex;
    align-items: center;
    justify-content: center;
}

.couponHolder {
    color: #879af2;
    padding-top: 10pt;
}

.manageDataError {
    color: #d3208b;
}

.manageAccountPopin {
    padding: 10pt;
}

.ctaZone {
    margin-top: 10pt;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.ctaHolder {
    margin-top: 10pt;
}

.expand {
    flex-grow: 1;
}
