:root {
    --ratio: 1;
}
@media all and (max-width: 450px) {
    :root {
        --ratio: 0.8;
    }
}

.imagePlaceHolderBox {
    width: 4vh;
    height: 4vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.imagePlaceHolder {
    max-height: 2vh;
    max-width: 2vh;
}

.inputTitle {
    font-family: "WorkSansRegular";
    font-size: 1.7vh;
    color: #301366;
    margin-top: 3vh;
    margin-bottom: 1.5vh;
}

.formTitle {
    font-family: "WorkSansBold";
    font-size: 3vh;
    margin-bottom: 1vh;
    color: #301366;
}

.formSubtitle {
    font-family: "WorkSansRegular";
    font-size: 2vh;
    color: #301366;
}

.formError {
    font-family: "WorkSansRegular";
    font-size: 2vh;
    color: #d3208b;
    margin-top: 1vh;
}

.ctaHolder {
    margin-top: 3vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.secondaryCTA {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
    font-family: "WorkSansRegular";
    color: #d3208b;
    font-size: 1.7vh;
}

.formHolder {
    padding: 3vw;
    max-width: 300pt;
    border-radius: calc(var(--ratio) * 25pt);
}
